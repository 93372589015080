@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  margin: 0;
}

button {
  background-color: rgb(96, 20, 196);
  border: none;
  color: white;
  padding: 12px;
  margin: 12px 0;
  box-shadow: 0 1px 6px #ccc;
  width: 100%;
  border-radius: 6px;
  font-size: 18px;
}
button:hover {
  background: mediumpurple;
  transition: 0.1s;
}
.container {
  box-shadow: 0 1px 6px #ccc;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 400px;
  padding: 22px;
}

.container > p {
  cursor: pointer;
  margin: 12px;
}

.error {
  background-color: tomato;
  color: white;
  border-radius: 6px;
  padding: 6px 12px;
  font-weight: bold;
  max-width: 400px;
  margin-bottom: 18px;
}
