body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
	  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
	  sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  }
  
  code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
	  monospace;
  }
  
  .App {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	flex-direction: column;
  }
  
  input {
	padding: 12px;
	margin: 12px;
	font-size: 14px;
	border: 1px solid #ccc;
	box-shadow: 0 1px 4px #ccc;
	border-radius: 6px;
	font-size: 18px;
	width: 93%;
  }
  
  button {
	background-color: rgb(96, 20, 196);
	border: none;
	color: white;
	padding: 12px;
	margin: 12px 0;
	box-shadow: 0 1px 6px #ccc;
	width: 100%;
	border-radius: 6px;
	font-size: 18px;
  }
  button:hover {
	background: mediumpurple;
	transition: 0.1s;
  }
  .container {
	box-shadow: 0 1px 6px #ccc;
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 400px;
	padding: 22px;
  }
  
  .container > p {
	cursor: pointer;
	margin: 12px;
  }
  
  .form {
	width: 100%;
  }
  
  .form > h2 {
	text-align: center;
  }
  
  .form > form {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
  }
  
  .form > form > p {
	cursor: pointer;
  }
  
  .error {
	background-color: tomato;
	color: white;
	border-radius: 6px;
	padding: 6px 12px;
	font-weight: bold;
	max-width: 400px;
	margin-bottom: 18px;
  }